import React from 'react'
import { graphql } from 'gatsby'

import * as styles from '../styles/pages/all-clubs.module.scss'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { FaFacebook, FaInstagram } from 'react-icons/fa'
import IconLink from '../components/IconLink'

export const Head = () => <title>All Clubs | GROWoneAFRICA&trade;</title>

const AllClubsPage = ({ data }) => {
  const allClubs = data.allSanityClub.nodes

  const clubElements = allClubs.map((club) => {
    const {
      name,
      logo,
      telephoneNumber,
      website,
      socialMediaLinks,
      address,
      googleMapsLink
    } = club

    return (
      <article className={styles.clubCard}>
        <div className={styles.imageContainer}>
          <GatsbyImage 
            image={getImage(logo.asset)} 
            alt={name}  
          />
        </div>
        <h2>{name}</h2>
        <div className={styles.socialMediaIcons}>
          {socialMediaLinks?.facebook &&
            <IconLink
              Icon={FaFacebook}
              title="Facebook"
              href={socialMediaLinks.facebook}
              target="_blank"
              rel="noreferrer"
            />
          }
          {socialMediaLinks?.instagram &&
            <IconLink
              Icon={FaInstagram}
              title="Instagram"
              href={socialMediaLinks.instagram}
              target="_blank"
              rel="noreferrer"
            />
          }
        </div>
        <div>
          <div>
            <h3>Telephone Number</h3>
            <span>
              <a href={`tel:${telephoneNumber}`}>{telephoneNumber}</a>
            </span>
          </div>
          <div>
            <h3>Website</h3>
            <span>
              <a href={website} target="_blank" rel="noreferrer">{website}</a>
            </span>
          </div>
          <div>
            <h3>Address</h3>
            <span>{address}</span>
          </div>
          <a 
            href={googleMapsLink}
            target="_blank"
            rel="noreferrer"
            className={styles.btnDirections}
          >Directions</a>
        </div>
      </article>
    )
  })

  return (
    <main className={`page-container ${styles.allClubsPage}`}>
      <h1>Clubs</h1>
      <section className={styles.clubsContainer}>
        {clubElements}
      </section>
    </main>
  )
}

export const query = graphql`
  query AllClubsPageQuery {
    allSanityClub(sort: {fields: name}) {
      nodes {
        name
        logo {
          asset {
            gatsbyImageData( height: 200 )
          }
        }
        telephoneNumber
        website
        socialMediaLinks {
          facebook
          instagram
        }
        address
        googleMapsLink
      }
    }
  }
`

export default AllClubsPage